import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Amplify } from 'aws-amplify';
// import awsConfig from "./aws-exports";
import "./index.css";

const updatedAwsConfig = {
  "aws_project_region": "us-east-1",
  "aws_cognito_identity_pool_id": process.env.REACT_APP_AWS_COGNITO_IDENTITY_POOL_ID,
  "aws_cognito_region": "us-east-1",
  "aws_user_pools_id": process.env.REACT_APP_AWS_USER_POOLS_ID,
  "aws_user_pools_web_client_id": process.env.REACT_APP_AWS_USER_POOLS_WEB_CLIENT_ID,
  "aws_cloud_logic_custom": [
    {
        "name": "stripeAPI",
        "endpoint": process.env.REACT_APP_STRIPE_END_POINT,
        "region": "us-east-1"
    }
  ]
  // oauth: {
  //   ...awsConfig.oauth,
  //   redirectSignIn: isLocalhost ? localRedirectSignIn : productionRedirectSignIn,
  //   redirectSignOut: isLocalhost ? localRedirectSignOut : productionRedirectSignOut,
  // },
}

Amplify.configure(updatedAwsConfig);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <App />
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
