import React, { useState } from 'react';
import LogoutButton from './LogOutButton';

const Header = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  return (
    <header className="bg-gray-800 text-white w-full flex items-center justify-between h-20 md:h-24">
      {/* logo - prevent shrinking */}
      <div className="flex-shrink-0 px-4">
        <a href="/" className="flex items-center space-x-2">
          <img src="/logo192.png" alt="Logo" className="h-20 md:h-24" />
        </a>
      </div>

      {/* desktop menu - centered */}
      <nav className="hidden md:flex flex-1 flex items-center justify-center">
        <a href="/sellers" className="hover:text-gray-300 px-3 py-2 rounded-md text-sm font-medium">Sellers</a>
        {/* Additional links can be added here */}
      </nav>

      <div className="hidden md:block flex-shrink-0 px-4">
        {/* Render the logout button on all screen sizes */}
        <LogoutButton />
      </div>

      {/* mobile menu button and dropdown */}
      <div className="md:hidden relative">
        <button
          onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
          className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700"
          id="mobile-menu-button"
        >
          {/* Icon for mobile menu */}
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
          <path strokeLinecap="round" strokeLinejoin="round" d="M3 12h18M4 6h16M5 18h14" />
        </svg>

        </button>
        {/* Dropdown menu */}
        {isMobileMenuOpen && (
          <div className="absolute right-0 mt-2 w-48 bg-gray-800 shadow-xl rounded-md py-1 text-white z-50">
            <a href="/sellers" className="block px-4 py-2 text-sm hover:bg-gray-700">Sellers</a>
            {/* Render the logout button inside the mobile menu */}
            <LogoutButton />
          </div>
        )}
      </div>
    </header>
  );
};

export default Header;
