import React, { useEffect, useState } from 'react';
import API from '../services/API';
import Loader from './Loader';
import LineChart from './LineChart';

// import WebSocketComponent from './Websocket';


const calculatePercentageChange = (oldNumber, newNumber ) => {
    if (oldNumber > 0 && newNumber < 0) {
        return newNumber + 100;
      } else if (oldNumber < 0 && newNumber > 0) {
        return newNumber - 100;
      } else {
        return newNumber - oldNumber;
      }
  }

  const GameInfo = ({ teamData, isFirstTeamInGame }) => {
    const start_time = new Date(teamData[0].start_time);
    const options = { hour: 'numeric', minute: 'numeric', timeZoneName: 'short', timeZone: 'America/Chicago' };
    const time = start_time.toLocaleString('en-US', options);
    const statusMapping = {
        'complete' : 'Final',
        'inprogress' : 'Live',
        'scheduled': 'Scheduled'
    }
    return (
        <div className="flex flex-col md:flex-row md:items-center text-left">
          {isFirstTeamInGame ? 
            <>
              <div className="w-full md:w-auto mb-2 md:mb-0 md:mr-4">{statusMapping[teamData[teamData.length-1].status]}</div>
              <div className="w-full md:w-auto mb-2 md:mb-0 md:mr-4">{time}</div>
              <div className="w-full md:w-auto">{teamData[0].full_name}</div>
            </> 
            :
            <>
            <div className="w-full md:w-auto mb-2 md:mb-0 md:mr-4"></div>
            <div className="w-full md:w-auto mb-2 md:mb-0 md:mr-4"></div>
            <div className="w-full md:w-auto md:ml-auto">{teamData[0].full_name}</div>
            </> 
          }
        </div>
      );
  };

  const lineTypes = ['odds','money_percent','tickets_percent'];

  const GameRow = ({ gameGroup, gameId }) => {
    return (
      <>
        {gameGroup.map((teamData, index) => (
          <tr className={index % 2 === 0 ? "border-t" : "border-b"} key={`${gameId}-${index}`}>
            <td className="p-2">
              <GameInfo teamData={teamData} isFirstTeamInGame={index === 0} key={'gameinfo' + index} />
            </td>
            {lineTypes.map((lineType,i) => {
                const lineData = teamData.filter(data => data.type ==='moneyline').sort((a,b) => a.timestamp - b.timestamp);

                if (lineData.length > 0) { // check that there is data for the current line type
                    // Get the most recent and earliest percentages
                    const mostRecentPercentage = lineData[lineData.length - 1][lineType];
                    const earliestPercentage = lineData[0][lineType];
    
                    // Calculate the percent change
                    const percentChange = calculatePercentageChange(earliestPercentage, mostRecentPercentage);
                    const color_line = percentChange >= 0 ? 'rgb(0, 200, 5)' : 'rgb(218, 65, 107)';

    
                    return (
                    <React.Fragment key={lineType + '-group' + i}>
                        <td className="p-2">
                            <LineChart data={lineData} lineType={lineType} color={color_line} />
                        </td>
                    <td className="p-2 text-right" >
                        {lineType==='odds' && mostRecentPercentage > 0 ? '+' : ''}
                        {mostRecentPercentage}
                        {lineType !== 'odds' ? '%': ''}
                        <br />
                        <span style={{color: color_line}}>
                        {percentChange.toFixed(2)}{lineType==='odds' ? '' : '%'}
                        </span>
                    </td>
                    </React.Fragment>
                    );
                } else {
                    return (
                        <React.Fragment key={lineType + '-group' + i}>
                    <td className="p-2" >
                        {/* <LineChart data={teamData} lineType={lineType} /> */}
                    </td>
                    <td className="p-2 bg-gray-200">
                        <div className="text-center text-gray-500">
                            N/A
                        </div>
                    </td>
                    </React.Fragment>

                    )

                }
                })}
          </tr>
        ))}
      </>
    );
  };
  

  const GamesList = ({ games }) => {
    const mapping = {
        'odds' : 'Money Line',
        'money_percent': '%Bets',
        'tickets_percent': '%Money'
    }
    return (
      <table className="w-full">
        <thead>
          <tr>
            <th className="p-2"></th>
            {lineTypes.map((lineType, i) => 
              <React.Fragment key={lineType + '-header' + i}>
                <th className="p-2"></th>
                <th className="p-2">{mapping[lineType]}</th>
              </React.Fragment>
            )}
          </tr>
        </thead>
        <tbody>
          {games.map((gameGroup, index) => <GameRow key={index} gameGroup={gameGroup} gameId={gameGroup[0][0].game_id} />)}
        </tbody>
      </table>
    );
  };
  
  const Table = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
  
    useEffect(() => {
      const getOdds = async () => {
        try {
          const result = await API.get("/daily_odds");
          // Group by game_id, then by team_name
          const grouped = result.data.reduce((groupedGames, game) => {
            const key = `${game.game_id}-${game.team_id}`; // Combine game_id and team_id to form a unique key
            if (!groupedGames[key]) {
              groupedGames[key] = [];
            }
            groupedGames[key].push(game);
            return groupedGames;
          }, {});
      
          // Convert the object to an array of arrays
          const teamDataArrays = Object.values(grouped);
      
          // Now group the teams by game
          const groupedByGame = teamDataArrays.reduce((groupedGames, teamData) => {
            const gameId = teamData[0].game_id;
            if (!groupedGames[gameId]) {
              groupedGames[gameId] = [];
            }
            groupedGames[gameId].push(teamData);

            return groupedGames;
          }, {});
          // Convert the object to an array of arrays
          const data = Object.values(groupedByGame);
          setData(data);
          setLoading(false);
        } catch (error) {
          console.log(error);
        }
      };
      getOdds(); 
    }, []);
  
    if (loading) {
      return <Loader />
    }
  
    return (
        <div className="w-full md:w-3/4 lg:w-1/2 mx-auto p-4">
            {/* <WebSocketComponent /> */}
            <GamesList games={data} />
        </div>
    );
  };
  
  export default Table;