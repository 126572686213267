import React, { useEffect, useRef, useLayoutEffect } from 'react';
import * as d3 from 'd3';

const LineChart = ({ data, lineType , color }) => {
  const chartsRef = useRef(null);
  const teamDivRef = useRef(null);

  useLayoutEffect(() => {
    const updateWidth = () => {
      if (teamDivRef.current) {
        const svg = d3.select(teamDivRef.current).select("svg");
        svg.style("width", "100%");
    }
    };
  
    updateWidth();
  
    window.addEventListener("resize", updateWidth);
  
    return () => {
      window.removeEventListener("resize", updateWidth);
    };
  }, []);

  useEffect(() => {
    const gameData = data
    const margin = { top: 5, right: 5, bottom: 5, left: 5 };
    
    const gameRow = d3.select(chartsRef.current)
    .append('div')
    .attr('class', 'flex flex-row w-full');
        

    // create a single div for the line chart
    teamDivRef.current = gameRow.append('div')
    .attr('class', 'w-[100%]')
    .node();

    const drawChart = () => {
      const lineData = gameData.map(d => {
        return {
          timestamp: d.timestamp,
          value: d[lineType],
        };
      }).filter(d => d.value != null && !isNaN(d.value) && d.value !== undefined && !isNaN(new Date(d.timestamp)));
      // Skip creating SVG and other elements if there's no data
      if (lineData.length === 0) {
        return;
      }
      const columnDiv = d3.select(teamDivRef.current).append('div').attr('class', 'w-full');

      const svgWidth = columnDiv.node().getBoundingClientRect().width;
      const x = d3.scaleTime()
        .domain(d3.extent(lineData, d => new Date(d.timestamp)))
        .range([0, svgWidth - margin.left - margin.right]);

      const aspectRatio = 16 / 9;
      const height = svgWidth / aspectRatio

      const [min, max] = d3.extent(lineData, d => d.value);
      const y = d3.scaleLinear()
      .domain(lineType === 'odds' ? [min - 5, max + 5] : [-5, 105])
      .range([height, 0]);

      const lineGenerator = d3.line()
      .x(d => x(new Date(d.timestamp)))
      .y(d => y(d.value));
      
      const svg = columnDiv.append('svg')
        .attr('width', '100%')
        .attr('height', height + margin.top + margin.bottom)
        // .append('g')
        // .attr('transform', `translate(${margin.left}, ${margin.top})`);
      
      const line = svg.append('path')
        .datum(lineData)
        .attr('fill', 'none')
        .attr('stroke', color)  // adjust the color to match the sparkline
        .attr('stroke-width', 1)  // adjust the width to match the sparkline
        .attr('d', lineGenerator)
        .attr('class', 'line');

      const focus = svg.append('circle')
        .style('opacity', 0)
        .attr('r', 5)
        .style('fill', 'none')
        .style('stroke', 'black');
    
    const tooltipClosest = d3.select('body')
      .append('div')
      .attr('class', 'tooltip')
      .style('opacity', 0);

      svg.on('mouseover', function(event) {
        d3.selectAll('.line').style('opacity', 0.1);
        line.style('opacity', 1);
      
        const mousePosition = d3.pointer(event);
        const mouseXValue = x.invert(mousePosition[0]);
        const closestDataPoint = lineData.reduce((prev, curr) => (
          Math.abs(curr.timestamp - mouseXValue) < Math.abs(prev.timestamp - mouseXValue) ? curr : prev
        ));
      
        tooltipClosest.html(`${closestDataPoint.value.toFixed(2)}${lineType === 'odds' ? '': '%'}`)
        .style('left', event.pageX + 'px')
        .style('top', event.pageY - 28 + 'px')
        .style('opacity', 0.9);
        
      })
      .on('mousemove', function(event) {
        const mousePosition = d3.pointer(event, this);
        const mouseDate = x.invert(mousePosition[0]);
        const bisectDate = d3.bisector(d => d.timestamp).right;
        const index = bisectDate(lineData, mouseDate);
        if (index > 0) {
          const closestDataPoint = lineData[index - 1];
          tooltipClosest.html(`${closestDataPoint.value.toFixed(2)}${lineType === 'odds' ? '': '%'}`)
              .style('left', event.pageX + 'px')
              .style('top', event.pageY - 28 + 'px')
              .style('opacity', 0.9);
          focus.attr('cx', x(new Date(closestDataPoint.timestamp)))
              .attr('cy', y(closestDataPoint.value))
              .style('opacity', 1);
        } else {
          // Handle the case where index - 1 is not a valid index
          // For example, you could hide the tooltip and focus
          tooltipClosest.style('opacity', 0);
          focus.style('opacity', 0);
        }
      })
      .on('mouseout', function() {
        d3.selectAll('.line').style('opacity', 1);
        tooltipClosest.style('opacity', 0);
        focus.style('opacity', 0);
      });

      const endPoint = lineData[lineData.length - 1];
      // const circleX = svgWidth * 0.9;
      const endCircle = svg.append('circle')
        .attr('cx', x(new Date(endPoint.timestamp)))
        .attr('cy', y(endPoint.value))
        .attr('r', 5)
        .style('fill', color)
        .style('opacity', 0.8);
    
    
        // svg.append('g')
        // .attr('transform', 'translate(0,' + height + ')')
        // .call(d3.axisBottom(x));

      // svg.append('g')
      //   .call(d3.axisLeft(y));
      

      svg.selectAll('circle')
      .transition()
      .duration(1000)
      .ease(d3.easeSin)
      .on('start', function pulse() {
        d3.active(this)
          .attr('r', 2)
          .transition()
          .attr('r', 4)
          .transition()
          .attr('r', 2)
          .transition()
          .on('start', pulse);
      });

      // Add a resize event listener


  window.addEventListener('resize', function() {
      // Get the new width
      const newWidth = columnDiv.node().getBoundingClientRect().width - margin.left - margin.right;
      const newHeight = newWidth / aspectRatio;

      // Update the x scale
      x.range([0, newWidth]);
      y.range([newHeight, 0]);

      // Update the line generator
      lineGenerator.x(d => x(new Date(d.timestamp)));
      lineGenerator.y(d => y(d.value));

      // Redraw the line
      line.attr('d', lineGenerator);

      // Redraw the text
      svg.select('text')
        .attr('x', newWidth/2)
        .attr('cy', y(endPoint.value));

      endCircle
      .attr('cx', x(new Date(endPoint.timestamp)))
      .attr('cy', y(endPoint.value));

      svg.style('height', newHeight + margin.top + margin.bottom + 'px');

      svg.select('.mouseover-path').remove();
      svg.on('mouseover', function(event) {
        d3.selectAll('.line').style('opacity', 0.1);
        line.style('opacity', 1);
      
        const mousePosition = d3.pointer(event);
        const mouseXValue = x.invert(mousePosition[0]);
        const closestDataPoint = lineData.reduce((prev, curr) => (
          Math.abs(curr.timestamp - mouseXValue) < Math.abs(prev.timestamp - mouseXValue) ? curr : prev
        ));
      
        tooltipClosest.html(`${closestDataPoint.value.toFixed(2)}${lineType === 'odds' ? '': '%'}`)
        .style('left', event.pageX + 'px')
        .style('top', event.pageY - 28 + 'px')
        .style('opacity', 0.9);
        
      })
      .on('mousemove', function(event) {
        const mousePosition = d3.pointer(event, this);
        const mouseDate = x.invert(mousePosition[0]);
        const bisectDate = d3.bisector(d => d.timestamp).right;
        const index = bisectDate(lineData, mouseDate);
        if (index > 0) {
          const closestDataPoint = lineData[index - 1];
          tooltipClosest.html(`${closestDataPoint.value.toFixed(2)}${lineType === 'odds' ? '': '%'}`)
              .style('left', event.pageX + 'px')
              .style('top', event.pageY - 28 + 'px')
              .style('opacity', 0.9);
          focus.attr('cx', x(new Date(closestDataPoint.timestamp)))
              .attr('cy', y(closestDataPoint.value))
              .style('opacity', 1);
        } else {
          // Handle the case where index - 1 is not a valid index
          // For example, you could hide the tooltip and focus
          tooltipClosest.style('opacity', 0);
          focus.style('opacity', 0);
        }
      })
      .on('mouseout', function() {
        d3.selectAll('.line').style('opacity', 1);
        tooltipClosest.style('opacity', 0);
        focus.style('opacity', 0);
      });

    });
    };
  
    // Immediately draw the chart once
    drawChart();

          

          
  }, [data, lineType,color]);

  return (
    <div>
      <div ref={chartsRef} className="flex flex-wrap justify-between"></div>
    </div>
  );
};

export default LineChart;
