import React, { useState, useEffect } from 'react';
import API from '../services/API';
import Loader from './Loader';

const SellerRefresh = () => {
  const [accountUrl, setAccountUrl] = useState('');
  const [isLoading, setIsLoading] = useState(true); // Start with loading true

  useEffect(() => {
    const getSignupLink = async () => {
      try {
        const response = await API.post('/createseller');
        const url = response.data.body.url; // Assuming the API returns an object with a url property
        setAccountUrl(url);
        window.location.href = url; // Redirect to the URL
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };

    getSignupLink();
  }, []);

  return (
    <div>
      <h1>Create Seller Account:</h1>
      {isLoading && <Loader />}
      {accountUrl !== '' && accountUrl}
    </div>
  );
};

export default SellerRefresh;
