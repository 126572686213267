import React, { useState,useEffect } from 'react';
import API from '../services/API';
import Loader from './Loader';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import QRCode from 'qrcode.react';

const SellerAccount = () => {
  const [accountId, setAccountId] = useState(null);
  const [chargedEnabled, setChargedEnabled] = useState(false)
  const [isLoading, setIsLoading] = useState(true);
  const [prices, setPrices] = useState(null)
  const [priceUrls, setPriceUrls] = useState({})
  const [copiedLinkIds, setCopiedLinkIds] = useState({});
  const [copiedQRCode, setCopiedQRCode] = useState({});
  const [stripePromoCode, setStripePromoCode] = useState('');
  const [errorPromoCode, setErrorPromoCode] = useState('');
  const [promoCode, setPromoCode] = useState('');
  const [copiedPromoCode, setCopiedPromoCode] = useState(false)
  const [prefillToggle, setPrefillToggle] = useState(false);

  useEffect(() => {
    const fetchAccountInfo = async () => {
        try {
            const data = await API.get('/connect_info')
            const stripeAccountId = data.data.account_id
            const stripeChargesEnabled = data.data.charges_enabled 
            const stripePriceUrls = data.data.price_urls || {}
            const stripePromoCode = data.data.promo_code || ''

            setAccountId(stripeAccountId);
            setChargedEnabled(stripeChargesEnabled);
            setPriceUrls(stripePriceUrls);
            setStripePromoCode(stripePromoCode)
            const initialCopiedState = Object.keys(stripePriceUrls).reduce((acc, priceId) => {
                acc[priceId] = false;
                return acc;
              }, {});
        
            setCopiedLinkIds(initialCopiedState);
            setCopiedQRCode(initialCopiedState);
      
            if (stripeAccountId && stripeChargesEnabled) {
              const prices_response = await API.get('/get_prices');
              setPrices(prices_response.data.data)
            }
          } catch (err) {
            console.log(err)
            return
          }
          setIsLoading(false);
        };
      
    fetchAccountInfo();
    return
  }, []);

  const getSignupLink = async () => {
    setIsLoading(true);
    try {
      const response = await API.post('/createseller');
      const url = response.data.url; // Assuming the API returns an object with a url property
      window.location.href = url; // Navigate to the URL
      return
    } catch (error) {
      console.log(error);
      return
    } finally {
      setIsLoading(false);
    }
  };

  const manageAccount = async () => {
    setIsLoading(true);
    try {
      const response = await API.post('/manageseller',{ data : { "account_id": accountId}});
      const url = response.data.url; // Assuming the API returns an object with a url property
      window.location.href = url; // Navigate to the URL
      return
    } catch (error) {
      console.log(error);
      return
    } finally {
      setIsLoading(false);
    }
  };

  const createPaymentLink = async (price_id) => {
    setIsLoading(true);
    try {
      const response = await API.post('/create_payment_link',{ data : {"account_id":accountId, "price_id": price_id}});
      const url = response.data; // Assuming the API returns an object with a url property
      setPriceUrls(prevUrls => ({ ...prevUrls, [price_id]: url }));
      setCopiedLinkIds(prevCopiedLinks => ({ ...prevCopiedLinks, [price_id]: false}));
      setCopiedQRCode(prevCopiedCodes => ({ ...prevCopiedCodes, [price_id]: false}));
    } catch (error) {
      console.log(error);
      return
    } finally {
      setIsLoading(false);
    }
  };

  const createPromoCode = async () => {
    setIsLoading(true);
    try {
      // Adjust the endpoint and payload as necessary for your API
      const data = await API.post('/create_promo', {data:  { 'account_id': accountId,'promo_code': promoCode }});
      setStripePromoCode(data.data.code)
      // Handle response if needed
    } catch (error) {
      const errorBody = error.body.error
      const message = errorBody.split(': ') 
      if (message.length > 0) {
        setErrorPromoCode(message.slice(1).join(': '))
      } else {
        setErrorPromoCode('An error occured. Please try again.') 
      }
      
    } finally {
      setIsLoading(false);
    }
  };

  const onCopy = (id, qr = false) => {
    if (qr) {
      setCopiedQRCode(id);
    } else {
      setCopiedLinkIds({ ...copiedLinkIds, [id]: true });
    }
    setTimeout(() => {
      if (qr) {
        setCopiedQRCode(null);
      } else {
        setCopiedLinkIds({ ...copiedLinkIds, [id]: false });
      }
    }, 1000); // Reset the copied state after 2 seconds for the specific link or QR code
  };

  const copyQRCodeToClipboard = async (id) => {
    const canvas = document.getElementById(`qrcode-${id}`);
    canvas.toBlob((blob) => {
      const item = new ClipboardItem({ "image/png": blob });
      navigator.clipboard.write([item]);
      onCopy(id, true);
    });
  };

  const onCopyPromo = () => {
    setCopiedPromoCode(true)
    setTimeout(() => {
        setCopiedPromoCode(false);
      }, 1000);
  }

  const getAffiliateUrl = (priceId) => {
    let url = priceId;
    if (prefillToggle && stripePromoCode) {
      url += `?prefilled_promo_code=${stripePromoCode}`;
    }
    return url;
  };

  return (
    <div>
      {isLoading ? <Loader /> : 
      accountId ? (
        <>
        <div className="flex flex-wrap w-full">
            <button onClick={() => manageAccount()} className="w-full sm:w-100 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 transition ease-in-out duration-150">
                Manage Seller Account
            </button>
            {!stripePromoCode ? 
            <div className="w-full bg-blue-100 p-4">
                <p className="text-blue-800 text-sm mb-2">Create a promo code to give customers 20% off their first 3 months. You receive a 40% commission on all revenue from that customer forever.</p>
                {errorPromoCode && <p className="text-red-500 text-sm">{errorPromoCode}</p>}
                <input
                type="text"
                value={promoCode}
                onChange={(e) => setPromoCode(e.target.value)}
                placeholder="Enter promo code"
                className="border-2 border-blue-300 rounded-lg py-2 px-4 text-gray-700 mb-4 w-full focus:outline-none focus:border-blue-500"
                />
                <button onClick={createPromoCode} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded transition duration-300 ease-in-out w-full">Create Promo Code</button>
            </div> :
            <div className="w-full bg-blue-100 p-4">
            <p className="text-blue-800 text-sm mb-2">Share this promo code to give customers 20% off their first 3 months if they are monthly. If they select 6 months or 1 year deposits they get 20% off their first purchase. You receive a 40% commission on all revenue from that customer forever.</p>
            <CopyToClipboard text={stripePromoCode} onCopy={() => onCopyPromo()}>
            <span className="w-full sm:w-1/2 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 cursor-pointer transition ease-in-out duration-150">
                {copiedPromoCode ? 'Copied': stripePromoCode}
            </span>
            </CopyToClipboard> 
            </div>
            }
            </div>
           <>
           <h1 className="text-2xl font-semibold text-center my-6">Affiliate Links</h1>
          <div className="flex flex-wrap justify-center gap-4 p-4">
            {chargedEnabled && prices.length > 0 ? (prices.map((price) => (
                <div key={price.id} className="max-w-md rounded overflow-hidden shadow-lg p-6 bg-white mx-auto my-6">
                <div className="mb-4 text-center">
                  <img src="/logo192.png" alt="Logo" className="mx-auto my-4 h-24" />
                  <p className="text-gray-700 text-base mb-2">
                    <span className="font-bold">Amount:</span> ${(price.unit_amount / 100).toFixed(2)}
                  </p>
                  {price.recurring && (
                    <div className="text-gray-700 text-base mb-2">
                      <p>
                        <span className="font-bold">Interval:</span> Every {price.recurring.interval_count} {price.recurring.interval}(s)
                      </p>
                      {/* {price.recurring.trial_period_days && (
                        <p>
                          <span className="font-bold">Trial Period:</span> {price.recurring.trial_period_days} days
                        </p>
                      )} */}
                    </div>
                  )}
                  <p className="text-gray-700 text-base mb-4">
                    <span className="font-bold">Type:</span> {price.type}
                  </p>
                </div>
                <div className="mb-4">
                  {priceUrls[price.id] ? (
                    <div>
                    <h2 className="text-center text-2xl font-semibold mb-4">Affiliate Link</h2>
                    <div className="payment-link-container">
                    <div className="url-container bg-gray-200 p-2 rounded flex items-center justify-between">
                    <CopyToClipboard text={getAffiliateUrl(priceUrls[price.id])} onCopy={() => onCopy(getAffiliateUrl(price.id))}>
                        <span className="text-gray-700 overflow-hidden text-ellipsis cursor-pointer hover:bg-gray-300">{priceUrls[price.id]}</span>
                    </CopyToClipboard>
                      <CopyToClipboard text={getAffiliateUrl(priceUrls[price.id])}
                        onCopy={() => onCopy(price.id)}>
                        <button className="copy-button ml-2 bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-2 rounded">
                            {copiedLinkIds[price.id] ? "Copied" : "Copy"}
                        </button>
                      </CopyToClipboard>
                    </div>
                    <div className="qr-code-container my-4 flex flex-col items-center">
                        <QRCode id={`qrcode-${getAffiliateUrl(price.id)}`} value={getAffiliateUrl(priceUrls[price.id])} size={68} />
                        <button 
                            className="copy-button mt-2 bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-2 rounded"
                            onClick={() => copyQRCodeToClipboard(getAffiliateUrl(price.id))}
                        >
                            {copiedQRCode === getAffiliateUrl(price.id) ? "QR Copied" : "Copy QR"}
                        </button>
                        </div>
                        <div className="toggle-container my-4">
                        <label className="flex items-center cursor-pointer">
                          <span className="mr-2 text-gray-700">Pre-fill Promo Code</span>
                          <div className={`${prefillToggle ? 'bg-blue-500' : 'bg-gray-300'} relative inline-block w-10 rounded-full h-6 transition-colors duration-200 ease-in-out`}>
                            <input 
                              type="checkbox" 
                              name="toggle" 
                              id="prefillToggle" 
                              className="toggle-checkbox opacity-0 w-0 h-0"
                              onChange={(e) => setPrefillToggle(e.target.checked)} 
                              checked={prefillToggle}
                            />
                            <span
                              className={`toggle-slider absolute block bg-white rounded-full w-6 h-6 transition-transform duration-200 ease-in-out ${prefillToggle ? 'transform translate-x-4' : 'transform translate-x-0'}`}
                              style={{ top: '0rem', left: prefillToggle ? '0rem' : '0.125rem' }} // Adjust for better centering
                            ></span>
                          </div>
                        </label>
                      </div>
                  </div>
                  </div>
                  ) : (
                    <button onClick={() => createPaymentLink(price.id)} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded transition duration-300 ease-in-out">
                      Generate Link
                    </button>
                  )}
                </div>
                <div className="mt-4 p-4 bg-blue-100 rounded-lg text-blue-800">
                  <p className="text-sm">By selling this service, you agree to our terms which include a 40% commission on all future earnings generated through this link.</p>
                </div>
              </div>
            ))):
            (
                <div className="max-w-sm rounded overflow-hidden shadow-lg p-6 bg-white">
                  <p className="text-gray-700 text-base">Finish setting up your account to start selling.</p>
                  <button onClick={getSignupLink} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                    Finish Setup
                  </button>
                </div>
              )}
            </div>
            </>
        </>
      ) : (
        <button onClick={() => getSignupLink()} className="w-full bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 transition ease-in-out duration-150">Create Seller Account</button>
      )}
    </div>
  );
};

export default SellerAccount;