import React from "react";
import { Loader as AWSLoader } from '@aws-amplify/ui-react'

const Loader = () => {
  return (
    <div className="flex items-center justify-center h-screen w-full">
        <AWSLoader width="5rem" height="5rem" />
    </div>
  );
};

export default Loader;