//App.js
import { Authenticator } from '@aws-amplify/ui-react';

//Components
import { RequireAuth } from './RequireAuth';
import { Login } from './components/Login';
import Header from './components/Header';
import SellerAccount from './components/SellerAccount';
import SellerRefresh from './components/SellerRefresh';
// import FrontPage from './components/FrontPage';
import Table from './components/Table'
// import LineChart from "./components/LineChart"


// // General
// import PrivacyPolicy from './components/PrivacyPolicy';
// import TermsOfService from './components/TermsOfService';

import { BrowserRouter, Routes, Route , Navigate } from 'react-router-dom';

import './App.css';

function MyRoutes() {
    return (
      <BrowserRouter>
        <Routes>
          <Route
            index
            path="/"
            element={
              <RequireAuth>
                 <div className="App">
                  <Header />
                  <div>
                  <Table/>
                  {/* <LineChart /> */}
                  </div>
                  </div>
              </RequireAuth>
            }
          />
          <Route
            index
            path="/sellers"
            element={
              <RequireAuth>
                 <div className="App">
                  <Header />
                  <div>
                  <SellerAccount/>
                  </div>
                  </div>
              </RequireAuth>
            }
          />
          <Route
            index
            path="/refresh"
            element={
              <RequireAuth>
                 <div className="App">
                  <Header />
                  <div>
                  <SellerRefresh/>
                  </div>
                  </div>
              </RequireAuth>
            }
          />
          <Route path="/login" element={<Login />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </BrowserRouter>
    );
}

function App() {
  return (
    <Authenticator.Provider>
      <MyRoutes />
    </Authenticator.Provider>
  );
}

export default App;

