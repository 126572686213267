import { Auth } from 'aws-amplify';

const LogoutButton = () => {
    const handleLogout = async () => {
      try {
        await Auth.signOut();
      } catch (error) {
        console.error('Error signing out: ', error);
      }
    };
  
    return (
      <button onClick={handleLogout} className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded transition ease-in-out duration-150">
        Logout
      </button>
    );
  };

export default LogoutButton;