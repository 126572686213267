// components/Login.js
import { useEffect } from "react";

import { Authenticator, useAuthenticator, View } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
// import { API } from 'aws-amplify';

import { useNavigate, useLocation } from 'react-router';

export function Login() {
  const { route } = useAuthenticator((context) => [context.route]);
  const location = useLocation();
  const navigate = useNavigate();
  let from = location.state?.from?.pathname || '/';


  useEffect(() => {
    if (route === 'authenticated') {
      navigate(from, { replace: true });
    }
  }, [route, navigate, from]);
  return (
    <div className="flex justify-center items-center h-screen bg-gray-100">
        <View className="auth-wrapper bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
            <Authenticator></Authenticator>
        </View>
    </div>
    
  );
}